import React, {useState}from 'react';
import './Geral.css';
import './Home.css';
import './index.css';
import Barchart from './Barchart';
import { FixedSizeList as List } from 'react-window';

const Home = () => {
  
  const [totalMovimentacao, setTotalMovimentacao] = useState(0);
  const [totalLucro, setTotalLucro] = useState(0);
  const [totalDeficit, setTotalDeficit] = useState(0);

  const data = [
    { name: "João Silva", date: "2024-09-06", transaction: "Transação via Pix", value: "R$ 200,00" },
    { name: "Maria Oliveira", date: "2024-09-05", transaction: "Transação via Pix", value: "R$ 150,00" },
    { name: "Pedro Santos", date: "2024-09-04", transaction: "Transação via Pix", value: "R$ 300,00" },
    { name: "Ana Costa", date: "2024-09-03", transaction: "Transação via Pix", value: "R$ 450,00" },
    { name: "Carlos Almeida", date: "2024-09-02", transaction: "Transação via Pix", value: "R$ 500,00" },
    { name: "Lucas Pereira", date: "2024-09-01", transaction: "Transação via Pix", value: "R$ 250,00" },
    { name: "Bruna Lima", date: "2024-08-31", transaction: "Transação via Pix", value: "R$ 350,00" },
    { name: "Felipe Rocha", date: "2024-08-30", transaction: "Transação via Pix", value: "R$ 400,00" },
    { name: "Juliana Souza", date: "2024-08-29", transaction: "Transação via Pix", value: "R$ 600,00" },
    { name: "Rodrigo Nunes", date: "2024-08-28", transaction: "Transação via Pix", value: "R$ 700,00" },
  ];

  const gastos = [
    { tipo: "RABBIT", data: "2024-09-06", custo: "R$ 200,00" },
    { tipo: "IUGU", data: "2024-09-05", custo: "R$ 150,00" },
    { tipo: "LEBRE", data: "2024-09-04", custo: "R$ 300,00" },
    { tipo: "FOXBIT", data: "2024-09-03", custo: "R$ 450,00" },
    { tipo: "SERVIDOR", data: "2024-09-02", custo: "R$ 500,00" },
    { tipo: "BINANCE", data: "2024-09-01", custo: "R$ 250,00" },
    { tipo: "FOXBIT", data: "2024-08-31", custo: "R$ 350,00" },
    { tipo: "IUGU", data: "2024-08-30", custo: "R$ 400,00" },
    { tipo: "RABBIT", data: "2024-08-29", custo: "R$ 600,00" },
    { tipo: "LEBRE", data: "2024-08-28", custo: "R$ 700,00" },
  ];

  return (

    <div className="home-container">

      <header className="home-header">

        <img src='./imagens/sampabank-logo.png' width={170} alt="SampaBank Logo" style={{left: 30}}/>

        <h4 style={{position: 'relative', right: '4%'}}></h4>

        <img src='./imagens/sino-icon.png' style={{position: 'relative', left: -10}} width={30}/>

        <span style={{position: 'relative', right: '1%', width: '7%',}}> <a href='Login' style={{color: 'white', fontWeight: 'bold'}}> Sair </a> </span>

      </header>

      <div className='body'>

        {/* LISTA DE NAVEGAÇÃO */}

        <ul className='ListaNavegacao'>

          <li className='itemLista1' style={{ backgroundColor: '#75757536'}}> <img src='./imagens/home-icon.png' width={40} style={{ position: 'relative', right: 70, top: 18 }} /> <a href=''>  Home</a> </li>
          <li className='itemLista2'> <img src='./imagens/extrato-icon.png' width={40} style={{ position: 'relative', right: 70, top: 18 }} /> <a href='/extrato'>  Extrato</a> </li>
          <li className='itemLista3'> <img src='./imagens/funcionarios-icon.png' width={40} style={{ position: 'relative', right: 70, top: 18 }}/> <a href='/clientes'>  Clientes</a> </li>
          <li className='itemLista4'> <img src='./imagens/funcionarios-icon.png' width={40} style={{ position: 'relative', right: 70, top: 18 }} /> <a href='/funcionarios'>  Funcionarios</a> </li>
            
        </ul>

        {/* AREA PRINCIPAL */}

        <div className='painelPrincipal'>

          <div className= 'Topo' style={{display: 'block', padding: '2%', paddingLeft: '2%', fontSize: 30,}}>

          </div>

          <div className='Info' style={{ display: 'flex', }}>

            <div className="info-item" style={{ fontSize: 40 }}>

              <div className='subtitulo' style={{ fontSize: 20, color: '#727272' }}>Movimentação</div>

              <span> R$ 2.53M </span>

              <span className='comparacao'>

                <img src='./imagens/aumento-icon.png' style={{width: 15, marginRight: 5}}/>

                50.1% 

              </span>

              <span className="tooltip"> R$ 2.530.000,00</span>

            </div>

            <div className="vertical-line"></div>
            
            <div className="info-item" style={{ fontSize: 40 }}>

              <div className='subtitulo' style={{ fontSize: 20, color: '#727272' }}>Saldo</div>

              <span> R$ 4.89M </span>

              <span className='comparacao'>

                <img src='./imagens/aumento-icon.png' style={{ width: 15, marginRight: 5 }} />

                50.1%

              </span>

              <span className="tooltip"> R$ 4.890.000,00</span>

            </div>

            <div className="vertical-line"></div>

            <div className="info-item" style={{ fontSize: 40 }}>

              <div className='subtitulo' style={{ fontSize: 20, color: '#727272' }}>Lucro</div>

              R$ 1.2K

              <span className='comparacao'>

                <img src='./imagens/aumento-icon.png' style={{ width: 15, marginRight: 5 }} />

                50.1%

              </span>

              <span className="tooltip">R$ 1.200,00</span>

            </div>

            <div className="vertical-line"></div>

            <div className="info-item" style={{ fontSize: 40 }}>

              <div className='subtitulo' style={{ fontSize: 20, color: '#727272' }}>Despesas</div>

              R$ 0.1K

              <span className='comparacao2'>

                <img src='./imagens/custo-icon.png' style={{ width: 15, marginRight: 5 }} />

                50.1%

              </span>

              <span className="tooltip">R$ 100,00</span>

            </div>

          </div>
          
        </div>

        <div className='painelGrafico'>

          <div style={{display: 'block', width: 300, position: 'absolute', fontWeight: 'bold', top: 36, left: -50}}> Gráfico Geral</div>

          <Barchart
            setTotalMovimentacao={setTotalMovimentacao}
            setTotalLucro={setTotalLucro}
            setTotalDeficit={setTotalDeficit}
          />

          <div className='painelDeficit'>

            <div style={{ position: 'absolute', left: 30, top: 20, fontWeight: 'bold' }}>Despesas do Mês</div>

            <List
              className='lista'
              height={500} // altura da lista
              itemCount={data.length} // número total de itens
              itemSize={80} // altura de cada item
              width={400} // largura da lista
            >

              {({ index, style }) => (
                <div className='itemLista' style={{ ...style, display: 'flex', justifyContent: 'space-between' }}>

                  <div style={{}}>
                    <strong style={{position: 'relative', top: 10,}}>{gastos[index].tipo}</strong>
                    <div style={{ fontSize: 12, position: 'absolute', bottom: 30 }}>{gastos[index].data}</div>
                  </div>

                  <div style={{ position: 'relative', right: 40, top: 15 }}>
                    <strong style={{ color: '#dd4c4c' }}>{gastos[index].custo}</strong>
                  </div>

                  <hr className='divisa-lista' />

                </div>
              )}

            </List>

          </div>

          <div className='painelGeral'>

            <div className='categoria' >
              <img src='./imagens/moviment-icon.png' width={35} height={35} />

              <div className='TotalMoviment' style={{ color: '#73a4ff', position: 'relative', top: 6}}>R$ {totalMovimentacao.toLocaleString()}</div>

            </div>

            <div className='categoria' >
              <img src='./imagens/lucro-icon.png' width={35} height={35} />

              <div className='TotalLucro' style={{ color: '#7dff73', position: 'relative', top: 6 }}>R$ {totalLucro.toLocaleString()}</div>

            </div>

            <div className='categoria' >
              
              <img src='./imagens/deficit-icon.png' width={35} height={35} />

              <div className='TotalDeficit' style={{ color: '#dd4c4c', position: 'relative', top: 6 }}>R$ {totalDeficit.toLocaleString()}</div>

            </div>

          </div>

        </div>

        {/* AREA LISTAGEM */}

        

        <div className='painelListagem'>
          
          <div style={{position: 'absolute', left: 60, top: 20, fontWeight: 'bold'}}>Transações recentes</div>

          <List
            className='lista'
            height={400} // altura da lista
            itemCount={data.length} // número total de itens
            itemSize={80} // altura de cada item
            width={1430} // largura da lista
          >
            {({ index, style }) => (
              <div className='itemLista' style={{ ...style, display: 'flex', justifyContent: 'space-between' }}>

                <div style={{}}>
                  <strong>{data[index].name}</strong>
                  <div style={{ fontSize: 12, position: 'absolute'}}>{data[index].date}</div>
                  <div style={{ fontSize: 12, position: 'absolute', left: 165 }}>Cash In</div>
                  <div style={{ fontSize: 12, position: 'absolute', top: 35 }}>{data[index].transaction}</div>
                </div>

                <div style={{position: 'relative', right: 40, top: 15}}>
                  <strong>{data[index].value}</strong>
                </div>

                <hr className='divisa-lista'/>

              </div>
            )}
          </List>

        </div>


      </div>

    </div>
  );
};

export default Home;
