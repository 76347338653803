import React, { useState } from 'react';
import './Geral.css';
import './Extrato.css';

const Extrato = () => {

      const clientes = [
    { nome: 'João Silva', cpf: '123.456.789-00', email: 'joao.silva@example.com', saldo: 'R$ 5,000.00' },
    { nome: 'Maria Oliveira', cpf: '987.654.321-00', email: 'maria.oliveira@example.com', saldo: 'R$ 3,200.50' },

  ];

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = clientes.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(clientes.length / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  
  return (

    <div className="home-container">

      <header className="home-header">

        <img src='./imagens/sampabank-logo.png' width={170} alt="SampaBank Logo" style={{ left: 30 }} />

        <h4 style={{ position: 'relative', right: '4%' }}></h4>

        <img src='./imagens/sino-icon.png' style={{ position: 'relative', left: -10 }} width={30} />

        <span style={{ position: 'relative', right: '1%', width: '7%', }}> <a href='Login' style={{ color: 'white', fontWeight: 'bold' }}> Sair </a> </span>

      </header>

      <body>

        {/* LISTA DE NAVEGAÇÃO */}

        <ul className='ListaNavegacao'>

          <li className='itemLista1'> <img src='./imagens/home-icon.png' width={40} style={{ position: 'relative', right: 70, top: 18 }} /> <a href='/home'>  Home</a> </li>
          <li className='itemLista2' style={{ backgroundColor: '#75757536' }}> <img src='./imagens/extrato-icon.png' width={40} style={{ position: 'relative', right: 70, top: 18 }} /> <a href=''>  Extrato</a> </li>
          <li className='itemLista3'> <img src='./imagens/funcionarios-icon.png' width={40} style={{ position: 'relative', right: 70, top: 18 }} /> <a href='/clientes'>  Clientes</a> </li>
          <li className='itemLista4'> <img src='./imagens/funcionarios-icon.png' width={40} style={{ position: 'relative', right: 70, top: 18 }} /> <a href='/funcionarios'>  Funcionarios</a> </li>

        </ul>

        {/* AREA PRINCIPAL */}

        <div className='ContainerLista'>

          <table style={{ width: '80%', borderCollapse: 'collapse', color: 'white' }}>
            <thead>
              <tr>
                <th className='Titulo'>Nome</th>
                <th className='Titulo'>CPF</th>
                <th className='Titulo'>Email</th>
                <th className='Titulo'>Saldo</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((cliente, index) => (
                <tr key={index}>
                  <td className='Titulo'>{cliente.nome}</td>
                  <td className='Titulo'>{cliente.cpf}</td>
                  <td className='Titulo'>{cliente.email}</td>
                  <td className='Titulo'>{cliente.saldo}</td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="pagination">
            <button onClick={handlePreviousPage} disabled={currentPage === 1}>
              &lt;
            </button>
            <span>{currentPage} / {totalPages}</span>
            <button onClick={handleNextPage} disabled={currentPage === totalPages}>
              &gt;
            </button>
          </div>

        </div>

      </body>

    </div>
  );
};

export default Extrato;