import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css';

const Login = () => {
  const [cpf, setCpf] = useState('');
  const [password, setPassword] = useState('');
  const [twoFACode, setTwoFACode] = useState(''); // Novo estado para o código 2FA
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const response = await fetch('https://samp-teste.jelastic.saveincloud.net/backoffice/login/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          cpf: cpf,
          password: password,
          '2fa_code': twoFACode, // Incluindo o código 2FA na requisição
        }),
      });

      const data = await response.json();

      if (response.ok) {
        // Armazena o JWT no localStorage
        localStorage.setItem('token', data.token);
        console.log(data.token);
        // Redireciona para a página 'home.js'
        navigate('/home');
      } else {
        // Exibe a mensagem de erro
        alert(data.error || 'Falha ao efetuar login.');
      }
    } catch (error) {
      alert('Ocorreu um erro inesperado. Tente novamente mais tarde.');
    }
  };

  return (
    <div className="home-container">
      <header className="home-headerlogin">
        <img src="./imagens/sampabank-logo.png" width={270} alt="SampaBank Logo" />
        <div className="negocio">
          <a href="">Ajuda</a>
          <a href="" style={{ marginLeft: 40, marginRight: 40 }}>
            Sobre
          </a>
          <a href="">Contato</a>
        </div>
      </header>

      <body style={{ alignContent: 'center' }}>
        <video autoPlay loop muted playsInline className="background-video">
          <source src="./videos/sampabank-video.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <div className="titulo">ADMINISTRAÇÃO SIMPLIFICADA, RESULTADOS AMPLIFICADOS</div>

        <div className="Login">
          <div>
            <input
              type="text"
              placeholder="CPF"
              className="input-field"
              value={cpf}
              onChange={(e) => setCpf(e.target.value)}
            />
          </div>

          <div>
            <input
              type="password"
              placeholder="Senha"
              className="input-field"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <div>
            <input
              type="text"
              placeholder="Código 2FA"
              className="input-field"
              value={twoFACode}
              onChange={(e) => setTwoFACode(e.target.value)}
            />
          </div>
        </div>

        <button className="button-28" role="button" onClick={handleLogin}>
          Entrar
        </button>
      </body>
    </div>
  );
};

export default Login;