import React, { useState } from 'react';
import './Geral.css';
import './Home.css';
import './index.css';

const Funcionarios = () => {

    const funcionarios = [
        { nome: 'Samuel de Souza Sampaio', ocupacao: 'Dono do morro', salário: 'R$ 0.01' },
        { nome: 'Leonardo Augusto Pinheiro Ferreira', ocupacao: 'Carimbador', salário: 'R$ 20.500,00' },
        { nome: 'Iuri Augusto', ocupacao: 'Golpista', salário: 'R$ -1.200,00' },
    ];


    return (

        <div className="home-container">

            <header className="home-header">

                <img src='./imagens/sampabank-logo.png' width={170} alt="SampaBank Logo" style={{ left: 30 }} />

                <h4 style={{ position: 'relative', right: '4%' }}></h4>

                <img src='./imagens/sino-icon.png' style={{ position: 'relative', left: -10 }} width={30} />

                <span style={{ position: 'relative', right: '1%', width: '7%', }}> <a href='Login' style={{ color: 'white', fontWeight: 'bold' }}> Sair </a> </span>

            </header>

            <div className='body'>

                {/* LISTA DE NAVEGAÇÃO */}

                <ul className='ListaNavegacao'>

                    <li className='itemLista1'> <img src='./imagens/home-icon.png' width={40} style={{ position: 'relative', right: 70, top: 18 }} /> <a href='/home'>  Home</a> </li>
                    <li className='itemLista2'> <img src='./imagens/extrato-icon.png' width={40} style={{ position: 'relative', right: 70, top: 18 }} /> <a href='/extrato'>  Extrato</a> </li>
                    <li className='itemLista3'> <img src='./imagens/funcionarios-icon.png' width={40} style={{ position: 'relative', right: 70, top: 18 }} /> <a href='/clientes'>  Clientes</a> </li>
                    <li className='itemLista4' style={{ backgroundColor: '#75757536' }}> <img src='./imagens/funcionarios-icon.png' width={40} style={{ position: 'relative', right: 70, top: 18 }} /> <a href=''>  Funcionarios</a> </li>

                </ul>

                {/* AREA PRINCIPAL */}

                <div className='ContainerLista'>
                    <table style={{ width: '80%', borderCollapse: 'collapse', color: 'white' }}>
                        <thead>
                            <tr>
                                <th className='Titulo'>Nome</th>
                                <th className='Titulo'>Ocupação</th>
                                <th className='Titulo'>Valor</th>
                            </tr>
                        </thead>
                        <tbody>
                            {funcionarios.map((funcionario, index) => (
                                <tr key={index}>
                                    <td className='Titulo'>{funcionario.nome}</td>
                                    <td className='Titulo'>{funcionario.ocupacao}</td>
                                    <td className='Titulo'>{funcionario.salário}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

            </div>

        </div>
    );
};

export default Funcionarios;
