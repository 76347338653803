// src/Clientes.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Geral.css';
import './Cadastro.css';

const Cadastro = () => {

  const formatCpfCnpj = (value) => {
    const cpfCnpjValue = value.replace(/\D/g, ''); // Remove caracteres não numéricos

    if (cpfCnpjValue.length <= 11) {
      // Formata como CPF: ###.###.###-##
      return cpfCnpjValue
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    } else if (cpfCnpjValue.length <= 14) {
      // Formata como CNPJ: ##.###.###/####-##
      return cpfCnpjValue
        .replace(/^(\d{2})(\d)/, '$1.$2')
        .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
        .replace(/\.(\d{3})(\d)/, '.$1/$2')
        .replace(/(\d{4})(\d{1,2})$/, '$1-$2');
    }

    return cpfCnpjValue; // Retorna sem formatação se diferente de CPF ou CNPJ
  };

  
  const [formData, setFormData] = useState({
    cpf_cnpj: '',
    nome_completo: '',
    data_nascimento: '',
    email: '',
    telefone: '',
    cep: '',
    rua: '',
    numero: '',
    banco: '',
    taxa_pix: '',
    taxa_boleto: ''
  });

  const [isFormValid, setIsFormValid] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [errorMessages, setErrorMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // Estado para o indicador de carregamento

  useEffect(() => {
    validateForm();
  }, [formData]);

  const handleChange = async (e) => {
    const { name, value } = e.target;

    const formatPhone = (value) => {
      const phoneValue = value.replace(/\D/g, ''); // Remove caracteres não numéricos
      if (phoneValue.length <= 11) {
        // Formata como (##) #####-####
        return phoneValue
          .replace(/(\d{2})(\d)/, '($1) $2')
          .replace(/(\d{5})(\d)/, '$1-$2');
      }
      return phoneValue; // Retorna sem formatação se maior que 11 dígitos
    };


    const formatCep = (value) => {
      const cepValue = value.replace(/\D/g, ''); // Remove caracteres não numéricos
      if (cepValue.length <= 8) {
        return cepValue.replace(/(\d{5})(\d)/, '$1-$2'); // Formata como #####-###
      }
      return cepValue; // Retorna sem formatação se maior que 8 dígitos
    };

    // Chama a função de formatação se o campo for CPF/CNPJ ou CEP
    const formattedValue = name === 'cpf_cnpj' ? formatCpfCnpj(value) : name === 'cep' ? formatCep(value) : value;

    // Atualiza o estado do formulário
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: formattedValue,
    }));
    
    // Lógica existente para CPF/CNPJ e outras
    if (name === 'cpf_cnpj') {
      const cpfCnpjValue = value.replace(/\D/g, '');
      if (cpfCnpjValue.length === 14) {
        // Requisição e lógica de CNPJ existente
      }
    }


    // Lida com mudanças específicas no campo CPF/CNPJ
    if (name === 'cpf_cnpj') {
      const cpfCnpjValue = value.replace(/\D/g, ''); // Remove caracteres não numéricos

      // Verifica se é um CNPJ (14 dígitos)
      if (cpfCnpjValue.length === 14) {
        try {
          setIsLoading(true); // Inicia o indicador de carregamento

          // Faz a requisição para obter os dados do CNPJ
          const response = await axios.get(`https://publica.cnpj.ws/cnpj/${cpfCnpjValue}`);

          if (response.data) {
            const companyData = response.data;

            // Extrai as informações necessárias e atualiza o estado do formulário
            setFormData((prevState) => ({
              ...prevState,
              nome_completo: companyData.razao_social || '',
              data_nascimento: companyData.estabelecimento.data_inicio_atividade || '',
              cep: companyData.estabelecimento.cep || '',
              rua: `${companyData.estabelecimento.tipo_logradouro || ''} ${companyData.estabelecimento.logradouro || ''}`.trim(),
              numero: companyData.estabelecimento.numero || '',
              email: companyData.estabelecimento.email || prevState.email,
              telefone: companyData.estabelecimento.ddd1 && companyData.estabelecimento.telefone1
                ? `${companyData.estabelecimento.ddd1}${companyData.estabelecimento.telefone1}`
                : prevState.telefone,
            }));

            // Se o CEP estiver preenchido, buscar detalhes do endereço
            const cepValue = companyData.estabelecimento.cep.replace(/\D/g, '');
            if (cepValue.length === 8) {
              try {
                const cepResponse = await axios.get(`https://viacep.com.br/ws/${cepValue}/json/`);
                if (!cepResponse.data.erro) {
                  setFormData((prevState) => ({
                    ...prevState,
                    rua: cepResponse.data.logradouro || prevState.rua,
                    // Você pode adicionar outros campos como bairro, cidade, estado, etc.
                  }));
                } else {
                  setResponseMessage('CEP não encontrado.');
                }
              } catch (error) {
                setResponseMessage('Erro ao buscar o CEP.');
              }
            }
          } else {
            setResponseMessage('CNPJ não encontrado.');
          }
        } catch (error) {
          setResponseMessage('Erro ao buscar dados do CNPJ.');
        } finally {
          setIsLoading(false); // Finaliza o indicador de carregamento
        }
      }
    }

    // Se o campo alterado for o CEP, remover caracteres não numéricos e buscar o endereço
    if (name === 'cep') {
      const cepValue = value.replace(/\D/g, '');
      if (cepValue.length === 8) {
        try {
          const response = await axios.get(`https://viacep.com.br/ws/${cepValue}/json/`);
          if (!response.data.erro) {
            setFormData((prevState) => ({
              ...prevState,
              rua: response.data.logradouro,
              // Você pode adicionar outros campos se desejar, como bairro, cidade, estado, etc.
            }));
          } else {
            setResponseMessage('CEP não encontrado.');
          }
        } catch (error) {
          setResponseMessage('Erro ao buscar o CEP.');
        }
      }
    }
  };

  const validateForm = () => {
    const { nome_completo, data_nascimento, cpf_cnpj, email, telefone, cep, rua, numero, banco, taxa_pix, taxa_boleto } = formData;
    if (
      nome_completo &&
      data_nascimento &&
      cpf_cnpj &&
      email &&
      telefone.length >= 10 && // Validação para telefone (pode variar)
      cep &&
      rua &&
      numero &&
      banco &&
      taxa_pix !== '' &&
      taxa_boleto !== ''
    ) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setResponseMessage('');
    setErrorMessages([]);
    setIsLoading(true); // Inicia o indicador de carregamento

    if (isFormValid) {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          setResponseMessage('Token não encontrado. Por favor, faça login novamente.');
          setIsLoading(false); // Finaliza o indicador de carregamento
          return;
        }

        const config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        };

        const response = await axios.post('https://samp-teste.jelastic.saveincloud.net/backoffice/users/registrar/', formData, config);

        if (response.data.success) {
          setResponseMessage('Cadastro realizado com sucesso!');
          // Limpar o formulário se necessário
          setFormData({
            cpf_cnpj: '',
            nome_completo: '',
            data_nascimento: '',
            email: '',
            telefone: '',
            cep: '',
            rua: '',
            numero: '',
            banco: '',
            taxa_pix: '',
            taxa_boleto: ''
          });
        } else if (response.data.errors) {
          // Exibir mensagens de erro retornadas pelo backend
          setErrorMessages(response.data.errors);
        } else if (response.data.error) {
          setResponseMessage(response.data.error);
        } else {
          setResponseMessage('Erro ao realizar o cadastro.');
        }
      } catch (error) {
        if (error.response) {
          // Erro vindo do servidor
          if (error.response.data.errors) {
            setErrorMessages(error.response.data.errors);
          } else if (error.response.data.error) {
            setResponseMessage(error.response.data.error);
          } else {
            setResponseMessage('Erro ao realizar o cadastro.');
          }
        } else {
          // Erro de rede ou outro
          setResponseMessage('Erro ao conectar com o servidor.');
        }
      } finally {
        setIsLoading(false); // Finaliza o indicador de carregamento
      }
    } else {
      setResponseMessage('Por favor, preencha todos os campos corretamente.');
      setIsLoading(false); // Finaliza o indicador de carregamento
    }
  };

  return (
    <div className="home-container">
      {/* HEADER */}
      <header className="home-header">
        <img src='./imagens/sampabank-logo.png' width={170} alt="SampaBank Logo" style={{ left: 30 }} />
        <h4 style={{ position: 'relative', right: '4%' }}></h4>
        <img src='./imagens/sino-icon.png' style={{ position: 'relative', left: -10 }} width={30} alt="Notificações" />
        <span style={{ position: 'relative', right: '1%', width: '7%' }}>
          <a href='Login' style={{ color: 'white', fontWeight: 'bold' }}> Sair </a>
        </span>
      </header>

      {/* BODY */}
      <body>
        {/* LISTA DE NAVEGAÇÃO */}
        <ul className='ListaNavegacao'>
          <li className='itemLista1'>
            <img src='./imagens/home-icon.png' width={40} style={{ position: 'relative', right: 70, top: 18 }} alt="Home" />
            <a href='/home'> Home</a>
          </li>
          <li className='itemLista2'>
            <img src='./imagens/extrato-icon.png' width={40} style={{ position: 'relative', right: 70, top: 18 }} alt="Extrato" />
            <a href='/extrato'> Extrato</a>
          </li>
          <li className='itemLista3' style={{ backgroundColor: '#75757536' }}>
            <img src='./imagens/funcionarios-icon.png' width={40} style={{ position: 'relative', right: 70, top: 18 }} alt="Clientes" />
            <a href=''> Clientes</a>
          </li>
          <li className='itemLista4'>
            <img src='./imagens/funcionarios-icon.png' width={40} style={{ position: 'relative', right: 70, top: 18 }} alt="Funcionários" />
            <a href='/funcionarios'> Funcionários</a>
          </li>
        </ul>

        {/* ÁREA PRINCIPAL */}

        <div style={{position: 'relative', color: '#FFF', fontSize: 24, left: 60, top: 50}}> CADASTRO DE CLIENTE</div>
        
        <form onSubmit={handleSubmit} className="ficha-cadastral" style={{ height: 900 }}>

          {isLoading && <p className="loading-message">Enviando dados, por favor aguarde...</p>}

          {responseMessage && <p className="response-message">{responseMessage}</p>}

          {errorMessages.length > 0 && (
            <ul className="error-messages">
              {errorMessages.map((error, index) => (
                <li key={index}>• {error}</li>
              ))}
            </ul>
          )}

          {/* CPF/CNPJ como primeiro campo */}

          <div>

            <label>CPF/CNPJ:</label>
            <input type="text" name="cpf_cnpj" value={formData.cpf_cnpj} onChange={handleChange} maxLength={18} required />

          </div>

          <div>

            <label>Nome Completo (Pessoal ou da Empresa):</label>
            <input type="text" name="nome_completo" value={formData.nome_completo} onChange={handleChange} required />

          </div>

          <div>

            <label>Data de Nascimento/Abertura:</label>
            <input type="date" name="data_nascimento" value={formData.data_nascimento} onChange={handleChange} required />

          </div>

          <div>

            <label>E-mail:</label>
            <input type="email" name="email" value={formData.email} onChange={handleChange} required />

          </div>

          {/* Flexbox para Telefone e CEP */}

          <div className="flex-row">
            
            <div className="half-width">

              <label>Telefone (DDD + número):</label>
              <input type="text" name="telefone" value={formData.telefone} onChange={handleChange} required />

            </div>

            <div className="half-width">

              <label>CEP:</label>
              <input type="text" name="cep" value={formData.cep} onChange={handleChange} maxLength={9} required />

            </div>
            
          </div>

          {/* Flexbox para Rua e Número */}
          <div className="flex-rua-numero">

            <div className="input-rua">

              <label>Rua:</label>

              <input type="text" name="rua" value={formData.rua} onChange={handleChange} required />

            </div>

            <div className="input-numero">

              <label>Número:</label>
              <input type="text" name="numero" value={formData.numero} onChange={handleChange} required />

            </div>

          </div>

          <div>

            <label>Banco:</label>

            <select name="banco" value={formData.banco} onChange={handleChange} required>

              <option value="">Selecione...</option>
              <option value="iugu">Iugu</option>
              <option value="voluti">Voluti</option>

            </select>

          </div>

          <div>

            <label>Taxa de Pix:</label>
            <input type="number" step="0.01" min="0" max="1" name="taxa_pix" value={formData.taxa_pix} onChange={handleChange} required />

          </div>

          <div>

            <label>Taxa de Boleto:</label>
            <input type="number" step="0.01" min="0" max="1" name="taxa_boleto" value={formData.taxa_boleto} onChange={handleChange} required />

          </div>

          <button type="submit" disabled={!isFormValid || isLoading} style={{ position: 'relative', top: 60 }}>

            {isLoading ? 'Enviando...' : 'Cadastrar'}

          </button>

        </form>
        
      </body>

    </div>
  );
};

export default Cadastro;
