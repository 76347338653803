// src/App.js
import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Home from './Home';
import Clientes from './Clientes';
import Extrato from './Extrato';
import Login from './Login';
import Funcionarios from './Funcionarios';
import Linechart from './Linechart';
import Barchart from './Barchart';
import ExtratoCliente from './ExtratoCliente';
import Cadastro from './Cadastro';

const App = () => {
  return (
    <Routes>
      {/* Redireciona a rota raiz para a página de login */}
      <Route path="/" element={<Navigate to="/login" />} />
      <Route path="/login" element={<Login />} />
      <Route path="/home" element={<Home />} />
      <Route path="/clientes" element={<Clientes />} />
      <Route path="/extrato" element={<Extrato />} />
      <Route path="/Linechart" element={<Linechart />} />
      <Route path="/Barchart" element={<Barchart />} />
      <Route path="/ExtratoCliente" element={<ExtratoCliente />} />
      <Route path="/funcionarios" element={<Funcionarios />} />
      <Route path="/Cadastro" element={<Cadastro />} />
    </Routes>
  );
};

export default App;
