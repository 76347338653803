// src/Clientes.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Geral.css';
import './Clientes.css';

const Clientes = () => {
  const [clientes, setClientes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;
  const [popupData, setPopupData] = useState(null); // Estado para os dados do popup
  const [showPopup, setShowPopup] = useState(false); // Estado para controlar a exibição do popup
  const [isEditing, setIsEditing] = useState(false); // Estado para modo de edição

  // Funções para lidar com a paginação
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = clientes.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(clientes.length / itemsPerPage);

  // Função para abrir o popup e fazer a requisição GET
  const handleShowPopup = async (mem_id) => {
    try {
      // Obtendo o token do localStorage
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error("Token não encontrado no localStorage");
      }

      // Fazer a requisição GET
      const response = await axios.get(`https://samp-teste.jelastic.saveincloud.net/backoffice/users/info/?id=${mem_id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      // Sucesso na obtenção dos dados
      setPopupData(response.data);
      setShowPopup(true); // Exibir o popup

    } catch (error) {
      console.error('Erro ao buscar os dados adicionais do cliente:', error);

      // Checando se o erro é devido a um token inválido
      if (error.response && error.response.data && error.response.data.error === "Falha ao validar token") {
        console.error('Token inválido:', error.response.data.error);
        window.location.href = '/login'; // Redireciona para a página de login
      }
    }
  };

  useEffect(() => {
    // Função que será executada ao pressionar qualquer tecla
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        handleClosePopup();
      }
    };

    // Adiciona o evento de escuta de teclas
    window.addEventListener("keydown", handleKeyDown);

    // Remove o evento ao desmontar o componente para evitar vazamentos de memória
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  
  // Função para fechar o popup
  const handleClosePopup = () => {
    setShowPopup(false);
    setPopupData(null); // Limpar os dados quando fechar
    setIsEditing(false); // Resetar o modo de edição
  };

  // Função para ativar o modo de edição
  const handleEdit = () => {
    setIsEditing(true);
  };

  // Função para salvar as alterações
  const handleSaveChanges = async () => {
    try {
      // Obtendo o token do localStorage
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error("Token não encontrado no localStorage");
      }

      // Fazer a requisição POST para atualizar os dados
      const response = await axios.post('https://samp-teste.jelastic.saveincloud.net/backoffice/users/editar/', popupData, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (response.data.success) {
        alert('Dados atualizados com sucesso.');
        setIsEditing(false);
        // Atualizar a lista de clientes se necessário
      } else {
        alert('Erro ao atualizar os dados: ' + response.data.error);
      }

    } catch (error) {
      console.error('Erro ao atualizar os dados do cliente:', error);
      alert('Erro ao atualizar os dados.');
    }
  };

  // Função para lidar com mudanças nos campos de entrada
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPopupData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleRedirect = (mem_id) => {
    window.location.href = `/extratoCliente?id=${mem_id}`;
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    const fetchClientes = async () => {
      try {
        // Obtendo o token do localStorage
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error("Token não encontrado no localStorage");
        }

        const response = await axios.get('https://samp-teste.jelastic.saveincloud.net/backoffice/users/', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        // Sucesso na obtenção dos dados, setar os clientes
        setClientes(response.data);

      } catch (error) {
        console.error('Erro ao buscar os dados dos clientes:', error);

        // Checando se o erro é devido a um token inválido
        if (error.response && error.response.data && error.response.data.error === "Falha ao validar token") {
          console.error('Token inválido:', error.response.data.error);
          window.location.href = '/login'; // Redireciona para a página de login
        }
      }
    };

    fetchClientes();
  }, []);

  return (
    <div className="home-container">
      {/* HEADER */}
      <header className="home-header">
        <img src='./imagens/sampabank-logo.png' width={170} alt="SampaBank Logo" style={{ left: 30 }} />
        <h4 style={{ position: 'relative', right: '4%' }}></h4>
        <img src='./imagens/sino-icon.png' style={{ position: 'relative', left: -10 }} width={30} />
        <span style={{ position: 'relative', right: '1%', width: '7%', }}>
          <a href='Login' style={{ color: 'white', fontWeight: 'bold' }}> Sair </a>
        </span>
      </header>

      {/* BODY */}
      <body>
        {/* LISTA DE NAVEGAÇÃO */}
        <ul className='ListaNavegacao'>
          <li className='itemLista1'>
            <img src='./imagens/home-icon.png' width={40} style={{ position: 'relative', right: 70, top: 18 }} />
            <a href='/home'> Home</a>
          </li>
          <li className='itemLista2'>
            <img src='./imagens/extrato-icon.png' width={40} style={{ position: 'relative', right: 70, top: 18 }} />
            <a href='/extrato'> Extrato</a>
          </li>
          <li className='itemLista3' style={{ backgroundColor: '#75757536' }}>
            <img src='./imagens/funcionarios-icon.png' width={40} style={{ position: 'relative', right: 70, top: 18 }} />
            <a href=''> Clientes</a>
          </li>
          <li className='itemLista4'>
            <img src='./imagens/funcionarios-icon.png' width={40} style={{ position: 'relative', right: 70, top: 18 }} />
            <a href='/funcionarios'> Funcionarios</a>
          </li>
        </ul>

        {/* ÁREA PRINCIPAL */}
        <div className='ContainerLista'>

          <a className='cadastroCliente' href='/Cadastro'>
            Cadastrar cliente
          </a>

          <table style={{ width: '80%', borderCollapse: 'collapse', color: 'white' }}>
            <thead>
              <tr>
                <th className='Titulo'>ID</th>
                <th className='Titulo'>Nome</th>
                <th className='Titulo'>CPF</th>
                <th className='Titulo'>Email</th>
                <th className='Titulo'>Saldo</th>
                <th className='Titulo'>Ações</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((cliente, index) => (
                <tr key={index}>
                  <td className='Titulo'>{cliente.mem_id}</td>
                  <td className='Titulo'>{cliente.firstname}</td>
                  <td className='Titulo'>{cliente.cpf}</td>
                  <td className='Titulo'>{cliente.email}</td>
                  <td className='Titulo'>
                    {(cliente.saldo / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                  </td>
                  <td className='Titulo'>
                    <img
                      src='./imagens/extrato-usuario.png'
                      width={25}
                      alt="Visualizar Extrato"
                      onClick={() => handleRedirect(cliente.mem_id)} // Redireciona com o mem_id
                      style={{ cursor: 'pointer', marginRight: '10px' }} // Adiciona margem direita entre os ícones
                    />
                    <img
                      src='./imagens/personal-icon.png'
                      width={25}
                      alt="Ver detalhes"
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleShowPopup(cliente.mem_id)} // Abre o popup com o mem_id
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* PAGINAÇÃO */}
          <div className="pagination">
            <button onClick={handlePreviousPage} disabled={currentPage === 1}>
              &lt;
            </button>
            <span>{currentPage} / {totalPages}</span>
            <button onClick={handleNextPage} disabled={currentPage === totalPages}>
              &gt;
            </button>
          </div>
        </div>

        {/* POPUP */}
        {showPopup && (
          <div className="popup-overlay">
            <div className="popup">
              <div className="popup-content">
                <h2>Detalhes do Cliente</h2>
                {popupData ? (
                  <div className="popup-details">
                    {/* Botão de Editar ou Salvar */}
                    {!isEditing ? (
                      <button className="edit-button" onClick={handleEdit}>Editar</button>
                    ) : (
                      <button className="save-button" onClick={handleSaveChanges}>Salvar</button>
                    )}

                    {/* Informações Pessoais */}
                    <h3>Informações Pessoais</h3>
                    <p><strong>ID:</strong> {popupData.mem_id}</p>
                    <p>
                      <strong>Nome:</strong>{' '}
                      {isEditing ? (
                        <>
                          <input
                            type="text"
                            name="firstname"
                            value={popupData.firstname || ''}
                            onChange={handleInputChange}
                          />{' '}
                          <input
                            type="text"
                            name="lastname"
                            value={popupData.lastname || ''}
                            onChange={handleInputChange}
                          />
                        </>
                      ) : (
                        `${popupData.firstname} ${popupData.lastname}`
                      )}
                    </p>
                    <p>
                      <strong>CPF/CNPJ:</strong>{' '}
                      {isEditing ? (
                        <input
                          type="text"
                          name="cpf"
                          value={popupData.cpf || ''}
                          onChange={handleInputChange}
                        />
                      ) : (
                        popupData.cpf || 'Não informado'
                      )}
                    </p>
                    <p>
                      <strong>Data de Nascimento:</strong>{' '}
                      {isEditing ? (
                        <input
                          type="date"
                          name="Birth"
                          value={popupData.Birth || ''}
                          onChange={handleInputChange}
                        />
                      ) : (
                        popupData.Birth ? new Date(popupData.Birth).toLocaleDateString('pt-BR') : 'Não informado'
                      )}
                    </p>
                    <p>
                      <strong>Data de Abertura:</strong>{' '}
                      {isEditing ? (
                        <input
                          type="date"
                          name="data_abertura"
                          value={popupData.data_abertura || ''}
                          onChange={handleInputChange}
                        />
                      ) : (
                        popupData.data_abertura ? new Date(popupData.data_abertura).toLocaleDateString('pt-BR') : 'Não informado'
                      )}
                    </p>
                    <p>
                      <strong>Estado Civil:</strong>{' '}
                      {isEditing ? (
                        <input
                          type="text"
                          name="estado_civil"
                          value={popupData.estado_civil || ''}
                          onChange={handleInputChange}
                        />
                      ) : (
                        popupData.estado_civil || 'Não informado'
                      )}
                    </p>
                    <p>
                      <strong>Nome da Mãe:</strong>{' '}
                      {isEditing ? (
                        <input
                          type="text"
                          name="nome_mae"
                          value={popupData.nome_mae || ''}
                          onChange={handleInputChange}
                        />
                      ) : (
                        popupData.nome_mae || 'Não informado'
                      )}
                    </p>

                    {/* Contato */}
                    <h3>Contato</h3>
                    <p>
                      <strong>Email:</strong>{' '}
                      {isEditing ? (
                        <input
                          type="email"
                          name="email"
                          value={popupData.email || ''}
                          onChange={handleInputChange}
                        />
                      ) : (
                        popupData.email
                      )}
                    </p>
                    <p>
                      <strong>Telefone:</strong>{' '}
                      {isEditing ? (
                        <input
                          type="text"
                          name="telNumber"
                          value={popupData.telNumber || ''}
                          onChange={handleInputChange}
                        />
                      ) : (
                        popupData.telNumber || 'Não informado'
                      )}
                    </p>
                    <p>
                      <strong>Telefone Alternativo:</strong>{' '}
                      {isEditing ? (
                        <input
                          type="text"
                          name="telefone"
                          value={popupData.telefone || ''}
                          onChange={handleInputChange}
                        />
                      ) : (
                        popupData.telefone || 'Não informado'
                      )}
                    </p>
                    <p>
                      <strong>Endereço:</strong>{' '}
                      {isEditing ? (
                        <input
                          type="text"
                          name="endereco"
                          value={popupData.endereco || ''}
                          onChange={handleInputChange}
                        />
                      ) : (
                        popupData.endereco || 'Não informado'
                      )}
                    </p>
                    <p>
                      <strong>Número:</strong>{' '}
                      {isEditing ? (
                        <input
                          type="text"
                          name="numero"
                          value={popupData.numero || ''}
                          onChange={handleInputChange}
                        />
                      ) : (
                        popupData.numero || 'Não informado'
                      )}
                    </p>
                    <p>
                      <strong>CEP:</strong>{' '}
                      {isEditing ? (
                        <input
                          type="text"
                          name="cep"
                          value={popupData.cep || ''}
                          onChange={handleInputChange}
                        />
                      ) : (
                        popupData.cep || 'Não informado'
                      )}
                    </p>

                    {/* Dados da Conta */}
                    <h3>Dados da Conta</h3>
                    <p>
                      <strong>Saldo:</strong>{' '}
                      {(popupData.saldo / 100).toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    </p>
                    <p>
                      <strong>Bloqueio:</strong>{' '}
                      {isEditing ? (
                        <select
                          name="bloqueio"
                          value={popupData.bloqueio}
                          onChange={handleInputChange}
                        >
                          <option value="1">Sim</option>
                          <option value="0">Não</option>
                        </select>
                      ) : popupData.bloqueio === '1' ? 'Sim' : 'Não'}
                    </p>
                    <p>
                      <strong>Depósito Máximo:</strong>{' '}
                      {isEditing ? (
                        <input
                          type="number"
                          name="deposito_maximo"
                          value={popupData.deposito_maximo || ''}
                          onChange={handleInputChange}
                        />
                      ) : (
                        popupData.deposito_maximo ? (popupData.deposito_maximo / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'Não informado'
                      )}
                    </p>
                    <p>
                      <strong>Taxa Pix:</strong>{' '}
                      {isEditing ? (
                        <input
                          type="number"
                          step="0.01"
                          name="taxa_pix"
                          value={popupData.taxa_pix || ''}
                          onChange={handleInputChange}
                        />
                      ) : (
                        popupData.taxa_pix ? `${parseFloat(popupData.taxa_pix) * 100}%` : 'Não informado'
                      )}
                    </p>
                    <p>
                      <strong>Taxa Boleto:</strong>{' '}
                      {isEditing ? (
                        <input
                          type="number"
                          name="taxa_boleto"
                          value={popupData.taxa_boleto || ''}
                          onChange={handleInputChange}
                        />
                      ) : (
                        popupData.taxa_boleto ? (parseFloat(popupData.taxa_boleto) / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'Não informado'
                      )}
                    </p>
                    <p>
                      <strong>Taxa Centavos:</strong>{' '}
                      {isEditing ? (
                        <input
                          type="number"
                          name="taxa_centavos"
                          value={popupData.taxa_centavos || ''}
                          onChange={handleInputChange}
                        />
                      ) : (
                        popupData.taxa_centavos ? (parseFloat(popupData.taxa_centavos) / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'Não informado'
                      )}
                    </p>
                    <p>
                      <strong>Antifraude:</strong>{' '}
                      {isEditing ? (
                        <select
                          name="antifraude"
                          value={popupData.antifraude}
                          onChange={handleInputChange}
                        >
                          <option value="1">Ativado</option>
                          <option value="0">Desativado</option>
                        </select>
                      ) : popupData.antifraude === '1' ? 'Ativado' : 'Desativado'}
                    </p>
                    <p>
                      <strong>Administrador:</strong>{' '}
                      { popupData.isAdmin === '1' ? 'Sim' : 'Não'}
                    </p>

                    {/* Outros */}
                    <h3>Outros</h3>
                    <p>
                      <strong>Nome Fantasia:</strong>{' '}
                      {isEditing ? (
                        <input
                          type="text"
                          name="slug"
                          value={popupData.slug || ''}
                          onChange={handleInputChange}
                        />
                      ) : (
                        popupData.slug || 'Não informado'
                      )}
                    </p>
                    <p>
                      <strong>Média de Faturamento:</strong>{' '}
                      {isEditing ? (
                        <input
                          type="text"
                          name="media_faturamento"
                          value={popupData.media_faturamento || ''}
                          onChange={handleInputChange}
                        />
                      ) : (
                        popupData.media_faturamento || 'Não informado'
                      )}
                    </p>
                    <p>
                      <strong>Banco:</strong>{' '}
                      {isEditing ? (
                        <input
                          type="text"
                          name="banco"
                          value={popupData.banco || ''}
                          onChange={handleInputChange}
                        />
                      ) : (
                        popupData.banco || 'Não informado'
                      )}
                    </p>
                    <p>
                      <strong>Nome do Banco:</strong>{' '}
                      {isEditing ? (
                        <input
                          type="text"
                          name="nome_banco"
                          value={popupData.nome_banco || ''}
                          onChange={handleInputChange}
                        />
                      ) : (
                        popupData.nome_banco || 'Não informado'
                      )}
                    </p>
                    <p>
                      <strong>Código do Banco:</strong>{' '}
                      {isEditing ? (
                        <input
                          type="text"
                          name="codigo_banco"
                          value={popupData.codigo_banco || ''}
                          onChange={handleInputChange}
                        />
                      ) : (
                        popupData.codigo_banco || 'Não informado'
                      )}
                    </p>
                    <p>
                      <strong>Agência:</strong>{' '}
                      {isEditing ? (
                        <input
                          type="text"
                          name="agencia"
                          value={popupData.agencia || ''}
                          onChange={handleInputChange}
                        />
                      ) : (
                        popupData.agencia || 'Não informado'
                      )}
                    </p>
                    <p>
                      <strong>Conta:</strong>{' '}
                      {isEditing ? (
                        <input
                          type="text"
                          name="conta"
                          value={popupData.conta || ''}
                          onChange={handleInputChange}
                        />
                      ) : (
                        popupData.conta || 'Não informado'
                      )}
                    </p>
                    <p>
                      <strong>Logo URL:</strong>{' '}
                      {isEditing ? (
                        <input
                          type="text"
                          name="logo_url"
                          value={popupData.logo_url || ''}
                          onChange={handleInputChange}
                        />
                      ) : (
                        popupData.logo_url || 'Não informado'
                      )}
                    </p>
                    <p>
                      <strong>Webhook:</strong>{' '}
                      {isEditing ? (
                        <input
                          type="text"
                          name="webhook"
                          value={popupData.webhook || ''}
                          onChange={handleInputChange}
                        />
                      ) : (
                        popupData.webhook || 'Não informado'
                      )}
                    </p>
                    <p>
                      <strong>Webhook Header:</strong>{' '}
                      {isEditing ? (
                        <input
                          type="text"
                          name="webhook_header"
                          value={popupData.webhook_header || ''}
                          onChange={handleInputChange}
                        />
                      ) : (
                        popupData.webhook_header || 'Não informado'
                      )}
                    </p>
                    <p>
                      <strong>Affiliate ID:</strong>{' '}
                      {isEditing ? (
                        <input
                          type="text"
                          name="affiliate_id"
                          value={popupData.affiliate_id || ''}
                          onChange={handleInputChange}
                        />
                      ) : (
                        popupData.affiliate_id || 'Não informado'
                      )}
                    </p>
                    <p>
                      <strong>Notify:</strong>{' '}
                      {isEditing ? (
                        <input
                          type="text"
                          name="notify"
                          value={popupData.notify || ''}
                          onChange={handleInputChange}
                        />
                      ) : (
                        popupData.notify || 'Não informado'
                      )}
                    </p>
                    <p>
                      <strong>Owner CPF:</strong>{' '}
                      {isEditing ? (
                        <input
                          type="text"
                          name="owner_cpf"
                          value={popupData.owner_cpf || ''}
                          onChange={handleInputChange}
                        />
                      ) : (
                        popupData.owner_cpf || 'Não informado'
                      )}
                    </p>
                    <p>
                      <strong>Nome:</strong>{' '}
                      {isEditing ? (
                        <input
                          type="text"
                          name="nome"
                          value={popupData.nome || ''}
                          onChange={handleInputChange}
                        />
                      ) : (
                        popupData.nome || 'Não informado'
                      )}
                    </p>
                    <p>
                      <strong>Nascimento:</strong>{' '}
                      {isEditing ? (
                        <input
                          type="date"
                          name="nascimento"
                          value={popupData.nascimento || ''}
                          onChange={handleInputChange}
                        />
                      ) : (
                        popupData.nascimento ? new Date(popupData.nascimento).toLocaleDateString('pt-BR') : 'Não informado'
                      )}
                    </p>
                    <p>
                      <strong>Tipo de Empresa:</strong>{' '}
                      {isEditing ? (
                        <input
                          type="text"
                          name="tipo_empresa"
                          value={popupData.tipo_empresa || ''}
                          onChange={handleInputChange}
                        />
                      ) : (
                        popupData.tipo_empresa || 'Não informado'
                      )}
                    </p>
                    <p>
                      <strong>Telegram ID:</strong>{' '}
                      {isEditing ? (
                        <input
                          type="text"
                          name="telegram_id"
                          value={popupData.telegram_id || ''}
                          onChange={handleInputChange}
                        />
                      ) : (
                        popupData.telegram_id || 'Não informado'
                      )}
                    </p>

                    {/* Aviso sobre dados sensíveis */}
                    <p style={{ color: 'red' }}>
                      ** Atenção: Dados sensíveis como senhas e tokens não são exibidos por razões de segurança. **
                    </p>

                  </div>
                ) : (
                  <p>Carregando...</p>
                )}
                <button className="close-button" onClick={handleClosePopup}>Fechar</button>
              </div>
            </div>
          </div>
        )}

      </body>
    </div>
  );
};

export default Clientes;
